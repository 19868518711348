<template>
  <div id="pagination">
    <b-pagination
      v-model="page"
      :total-rows="total"
      :per-page="limit"
      aria-controls="my-pagination"
      :hide-goto-end-buttons="true"
      prev-text="❮❮"
      next-text="❯❯"
      @page-click="handleChangePage"
      align="center"
    ></b-pagination>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: ["currentPage", "total", "limit"],
  data() {
    return {
      page: this.currentPage
    };
  },
  watch: {
    currentPage(val) {
      this.page = val;
    }
  },
  methods: {
    handleChangePage(event, page) {
      this.$emit("selectedPage", page);
    }
  }
};
</script>

<style>
.page-link {
  border-radius: 50% !important;
  width: 40px !important;
  height: 40px !important;
  margin-left: 8px;
  line-height: 1.5 !important;
  font-size: 14px;
  font-family: "Hiragino Kaku Gothic Pro W3";
  color: #464d77;
}

.page-item.active .page-link {
  background-color: #464d77;
  border-color: #464d77;
  line-height: 1.5 !important;
}

.page-item.disabled .page-link {
  color: #464d77;
  line-height: 1.5 !important;
}

.page-link:focus {
  box-shadow: unset;
}
</style>
