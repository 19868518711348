<template>
  <AppContainer :useBackground="true" :useShadow="false">
    <div id="view-distance" class="f-w3 position-relative" v-if="listUsers">
      <div id="view-distance-detail">
        <HeaderSuggest @closeModalSuggestUser="closeModalSuggestUser" />
        <div class="image-avatar">
          <div @click="nextProfile()">
            <div class="avatar">
              <img
                :src="listUsers ? listUsers[index - 1].image_url : ''"
                alt="image-avatar"
              />
            </div>
          </div>
          <DotView :index="index" :total="total" />
          <div class="nick__name">
            <p class="nick-name f-w6 mb-0">
              {{ listUsers[index - 1].nickname }} ({{
                getAge(listUsers[index - 1].date_of_birth)
              }})
            </p>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <div class="view-distance__distance d-flex justify-content-center">
              <img src="@/assets/image/locale.svg" alt="" />
              <span class="distance">{{ getDistance() }}</span>
              <span>{{
                listUsers ? listUsers[index - 1].prefecture_name : ""
              }}</span>
            </div>
            <div class="view-distance--detail">
              <div class="__detail d-flex justify-content-center">
                <div class="view-distance--detail__left text-right">
                  <div class="view-distance-point d-flex">
                    <p class="view-distance-point__text">Ave.</p>
                    <p class="view-distance-point__point f-w6">
                      {{ listUsers ? listUsers[index - 1].avg_score : "" }}
                    </p>
                  </div>
                </div>
                <div class="border-score" />
                <div class="view-distance--detail__right text-left">
                  <div class="view-distance-point d-flex">
                    <p class="view-distance-point__text">Best.</p>
                    <p class="view-distance-point__point f-w6">
                      {{ listUsers ? listUsers[index - 1].best_score : "" }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="view-distance--detail">
          <!-- <div
            class="router d-flex justify-content-center"
            @click.prevent="nextProfile()"
          >
            もっと見る
          </div> -->
          <div
            class="d-flex justify-content-center router"
            v-if="listUsers && listUsers[index - 1].sex === 1"
          >
            <div
              class="view-distance--detail__text"
              v-if="listUsers[index - 1].car_flag === 1"
            >
              車あり
            </div>
            <div
              class="view-distance--detail__text"
              :class="listUsers[index - 1].car_flag === 1 ? '__right' : ''"
              v-if="listUsers[index - 1].has_golf_membership === 1"
            >
              会員権あり
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <div
              class="skip d-flex flex-column justify-content-center"
              :class="first == 0 ? '__disabled' : ''"
              @click.stop="backUser()"
            >
              <img
                src="@/assets/image/icon-back.svg"
                class="image-send-message"
                alt=""
              />
            </div>
            <div
              id="like-user"
              class="like"
              :class="isLike ? 'liked' : ''"
              @click.stop="likeUser"
              v-mojs="mojsOptions"
            >
              <div class="image-like">
                <svg
                  version="1.1"
                  id="fb-like"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 194 178"
                  style="enable-background: new 0 0 194 178"
                  xml:space="preserve"
                  fill="#ffffff"
                >
                  <g id="hand-wrapper">
                    <path
                      id="hand"
                      class="st0"
                      d="M119.1,70.1c0,0,20.8-1.2,28.5,0c7.7,1.2,10.3,2,13.8,6.2s2.4,10.5,1.8,12.4c-0.7,1.9-2,3.7-0.2,6
    s2.5,7.6,1.4,11.9c-1.1,4.2-3.3,6.6-3.3,6.6s0.1,11.1-3.4,16.6c-3.5,5.6-3.3,3.3-3.3,7.7s-3.5,9.2-10.4,11.8s-24,4.5-48.5,4.3
    c0,0-12.7-0.3-17.9-8.3s-3.8-21.2-3.8-21.2s-0.7-22.5-0.4-25.8s0.4-12.7,10.4-28.2H119.1z"
                    />
                    <path
                      id="thumb"
                      class="st0"
                      d="M82.7,72c0,0,11.4-17.3,11.7-20.7s-0.8-12.8-0.4-18.7s2.3-8.1,9.9-8.2s14.8,5,17.3,13.8
    s-1.3,20.6-1.3,20.6s-2.1,5.6-2.4,6.8s-2.3,5,1.7,4.8l1.3,0.1"
                    />
                  </g>
                  <path
                    id="arm"
                    class="st0"
                    d="M36.6,150.3c0,0-4.1-2.5-5.9-11.8s-2.1-39.7-1.3-47.2s2.8-12.4,11.4-12.8s12-0.3,14.5,0.6s7,2.1,8,7.1
    c1,5-1,7.4,0.9,52.2c0,0,1.4,5.8-4.8,11.8S36.6,150.3,36.6,150.3z"
                  />
                </svg>
              </div>
              <p class="mb-0">いいね</p>
            </div>
            <div
              class="skip d-flex flex-column justify-content-center"
              @click.stop="nextUser()"
            >
              <img
                src="@/assets/image/icon-skip.svg"
                class="image-send-message"
                alt=""
              />
            </div>
          </div>
        </div>
        <div
          class="d-flex form-message"
          id="form-message"
          :class="isSendMessage ? '__disabled' : ''"
        >
          <textarea
            id="textarea"
            placeholder="打ちっ放し行きませんか？"
            v-model="inputMsg"
            rows="1"
            class="f-w3"
            @keyup="focusMessageIn"
            @keydown="pressEnter"
            @focus="focusMessage"
            @keyup.enter="handleSubmitKeyboard"
            maxlength="2000"
            :readonly="readonlyMsg == 1"
          ></textarea>
          <button
            @click.stop="sendFastMessage"
            class="
              btn btn-send
              d-flex
              align-items-center
              justify-content-center
            "
          >
            <img
              src="@/assets/image/send-message.svg"
              class="image-send-message"
              alt="send message"
            />
          </button>
        </div>
      </div>
    </div>
    <ModalLayout ref="identifyPopup">
      <IdentifyPopup
        @identify="identify"
        :identity_status="authUser.identity_status"
        :sex="authUser.sex"
      />
    </ModalLayout>
    <ModalLayout ref="addMonthlyPlanPopup">
      <AddMonthlyPlanPopup @close-modal="closeAddMonthlyPlanPopup" />
    </ModalLayout>
  </AppContainer>
</template>

<script>
import HeaderSuggest from "@/components/HeaderSuggest.vue";
import { INDIVIDUAL_ROOM_TYPE } from "@/constants/message";
import DotView from "@/components/DotView";
import { mapActions, mapGetters } from "vuex";
import mojs from "mo-js";
import { urlText } from "@/utils/ui";
import {
  LIMIT_USER_SUGGEST,
  TYPE_USER_SUGGEST_1,
  TYPE_USER_SUGGEST_2
} from "@/definition/constants";
import gsap from "gsap";
import IdentifyPopup from "@/components/IdentifyPopup.vue";
import AddMonthlyPlanPopup from "@/components/AddMonthlyPlanPopup";

export default {
  components: {
    HeaderSuggest,
    DotView,
    IdentifyPopup,
    AddMonthlyPlanPopup
  },
  data() {
    return {
      readonlyMsg: 0,
      tl: null,
      LIMIT_USER_SUGGEST,
      TYPE_USER_SUGGEST_1,
      TYPE_USER_SUGGEST_2,
      type: TYPE_USER_SUGGEST_1,
      users: {},
      isLoading: false,
      index: 1,
      isLike: false,
      isSendMessage: false,
      inputMsg: null,
      listUsers: null,
      total: 1,
      totalAll: 1,
      userLogin: null,
      roomId: null,
      userId: null,
      suggest: false,
      first: 0,
      likeNumber: 0,
      mojsOptions:
        window.innerWidth < 1080
          ? {
              radius: { 40: 60 },
              count: 15,
              angle: { 0: 20 },
              children: {
                delay: 250,
                duration: 700,
                radius: { 5: 0 },
                fill: ["#ddca7e", "pink"],
                easing: mojs.easing.bezier(0.08, 0.69, 0.39, 0.97)
              }
            }
          : {
              radius: { 90: 60 },
              count: 15,
              angle: { 0: 20 },
              children: {
                delay: 250,
                duration: 700,
                radius: { 5: 0 },
                fill: ["#ddca7e", "pink"],
                easing: mojs.easing.bezier(0.08, 0.69, 0.39, 0.97)
              }
            }
    };
  },

  async created() {
    localStorage.removeItem("listUsersSuggest");
    this.userId = this.$route.params.id;
    if (this.userId) {
      this.likeNumber = Number(localStorage.getItem("likeNumber"));
    } else {
      localStorage.removeItem("likeNumber");
    }
    this.type = this.$route.params.type ? this.$route.params.type : this.type;
    await this.getListUsers(this.type);
    if (this.authUser.sex === 1) {
      await this.getPaymentMonthPacket();
    }
  },

  computed: {
    ...mapGetters({
      authUser: "auth/user",
      listUsersSuggest: "userApp/listUsersSuggest",
      monthlyPlanStatus: "payment-stripe/monthlyPlanStatus"
    })
  },

  updated() {
    this.animation();
  },

  methods: {
    ...mapActions("firebase", ["createRoom"]),
    ...mapActions("payment-stripe", ["getPaymentMonthPacket"]),
    closeModalSuggestUser() {
      window.location.reload();
    },

    animation() {
      this.tl = gsap.timeline();
      this.tl.to("#fb-like", 0.3, {
        rotate: 25,
        scale: 0.8,
        ease: "power1.easeOut"
      });
      this.tl.to("#hand-wrapper", 0.3, {
        delay: -0.3,
        rotate: 5,
        y: 10,
        x: 5,
        ease: "back.easeOut"
      });
      this.tl.to("#fb-like", 0.6, {
        delay: -0.15,
        rotate: -25,
        scale: 1.2,
        ease: "back.easeOut"
      });
      this.tl.to("#hand-wrapper", 0.3, {
        delay: -0.6,
        rotate: -10,
        y: -5,
        x: -15,
        ease: "back.easeOut"
      });
      this.tl.to("#fb-like", 0.6, {
        delay: -0.15,
        rotate: 0,
        scale: 1,
        ease: "back.easeOut"
      });
      this.tl.to("#hand-wrapper", 0.3, { delay: -0.6, rotate: 0, y: 0, x: 0 });
      this.tl.pause();
    },

    getAge(dateOfBirth) {
      const current = new Date().getFullYear();
      const birthday = new Date(dateOfBirth).getFullYear();
      return current - birthday;
    },

    async getListUsers(type) {
      let params = {
        limit: LIMIT_USER_SUGGEST,
        list_users: localStorage.getItem("listUsersSuggest")
          ? JSON.parse(localStorage.getItem("listUsersSuggest"))
          : [],
        type: type
      };
      if (this.userId && !this.suggest) {
        params = {
          limit: LIMIT_USER_SUGGEST,
          list_users: localStorage.getItem("listUsersSuggest")
            ? JSON.parse(localStorage.getItem("listUsersSuggest"))
            : [],
          type: type,
          user_id: this.$route.params.id
        };
      }
      await this.$store
        .dispatch("userApp/getListSuggestUsers", params)
        .then(res => {
          this.listUsers = res.data.data.data;
          let listUserIds = this.listUsers.map(item => {
            return item.user_id;
          });
          this.total = res.data.data.totalUser;
          this.totalAll = res.data.data.total;
          this.type = res.data.data.type;
          this.userLogin = res.data.data.userLogin;
          let listUsersSuggest = localStorage.getItem("listUsersSuggest");
          if (
            listUsersSuggest &&
            JSON.parse(listUsersSuggest).length < this.totalAll
          ) {
            listUserIds = JSON.parse(listUsersSuggest).concat(listUserIds);
          }
          localStorage.setItem("listUsersSuggest", JSON.stringify(listUserIds));
        });
    },
    async sendFastMessage() {
      if (!this.isSendMessage) {
        this.inputMsg = this.inputMsg ? this.inputMsg.trim() : this.inputMsg;
        if (this.inputMsg && this.inputMsg.length > 2000) {
          this.$toast("2000文字以内にしてください", "通知", "danger");
        } else if (this.inputMsg) {
          const readUsers = {};
          readUsers[this.userLogin.user_id] = true;
          readUsers[this.listUsers[this.index - 1].user_id] = false;
          this.$root.$refs.loading.start();
          await this.createRoom();
          const data = {
            roomId: this.roomId,
            message: {
              type: 1,
              userId: this.userLogin.user_id,
              message: urlText(this.inputMsg.trim()),
              imageUrl: "",
              giftId: null,
              createdAt: new Date(),
              readUsers: readUsers
            }
          };
          await this.sendMessage(data, true);
          this.$root.$refs.loading.finish();
          this.isSendMessage = true;
        }
      }
    },
    async closeAddMonthlyPlanPopup() {
      this.$refs.addMonthlyPlanPopup.closeModal();
    },
    getDistance() {
      if (this.userLogin && this.listUsers) {
        const distance = this.distance(
          this.userLogin.latitude,
          this.userLogin.longitude,
          this.listUsers[this.index - 1].latitude,
          this.listUsers[this.index - 1].longitude
        );
        if (distance < 5) {
          return "5.0km以内";
        } else if (distance > 100) {
          return "100km以上";
        } else if (distance <= 10) {
          return Math.ceil(distance) + ".0km";
        } else {
          return Math.ceil(distance / 5) * 5 + ".0km";
        }
      }
    },
    distanceToUser() {
      if (this.userLogin && this.listUsers) {
        const distance = this.distance(
          this.userLogin.latitude,
          this.userLogin.longitude,
          this.listUsers[this.index - 1].latitude,
          this.listUsers[this.index - 1].longitude
        );
        return distance;
      }
    },
    async likeUser() {
      if (!this.isLike) {
        document.getElementById("view-distance").style.cssText =
          "pointer-events: none";
        this.isLike = true;
        this.likeNumber++;
        localStorage.setItem("likeNumber", this.likeNumber);
        this.tl.play();
        const self = this;
        setTimeout(function() {
          self.tl.progress(0);
          self.tl.pause();
        }, 1000);
        const readUsers = {};
        readUsers[this.userLogin.user_id] = true;
        readUsers[this.listUsers[this.index - 1].user_id] = false;
        // this.$root.$refs.loading.start();
        await this.createRoom();
        const data = {
          roomId: this.roomId,
          message: {
            type: 1,
            userId: this.userLogin.user_id,
            message: null,
            imageUrl: "",
            giftId: null,
            createdAt: new Date(),
            readUsers: readUsers,
            isLike: true
          }
        };
        await this.sendMessage(data, false);
        if (this.likeNumber === 3) {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          setTimeout(() => {
            this.nextUser();
            document.getElementById("view-distance").style.cssText =
              "pointer-events: unset";
          }, 1000);
        }
      }
    },
    async sendMessage(data, like) {
      await this.$store
        .dispatch("firebase/createMessage", data)
        .then(() => {
          if (like) {
            this.$toast("メッセージ送信しました", "通知", "success");
          }
          this.sendNotification();
          this.sendMessageToUserLine(data.roomId);
          this.inputMsg = null;
          let style;
          if (window.innerWidth < 1080) {
            style = "height: 37px; padding: 8px 10px;";
          } else {
            style = "height: 52px; padding: 16px;";
          }
          document.querySelector("textarea").style.cssText = style;
        })
        .catch(error => {
          this.$toast("メッセージ送信に失敗しました", "通知", "danger");
          throw error;
        });
    },
    sendNotification() {
      this.$store.dispatch("firebase/sendPushNotification", {
        to_user_ids: [this.listUsers[this.index - 1].user_id]
      });
    },
    async createRoom() {
      let data = {
        hiddenUserIds: {
          [this.userLogin.user_id]: true,
          [this.listUsers[this.index - 1].user_id]: true
        },
        userIds: [
          this.userLogin.user_id,
          this.listUsers[this.index - 1].user_id
        ],
        adId: null,
        type: INDIVIDUAL_ROOM_TYPE
      };
      await this.$store
        .dispatch("firebase/createRoom", data)
        .then(result => {
          this.roomId = result;
        })
        .catch(error => {
          this.roomId = null;
          throw error;
        });
    },
    distance(lat1, lon1, lat2, lon2) {
      let p = 0.017453292519943295; // Math.PI / 180
      let c = Math.cos;
      let a =
        0.5 -
        c((lat2 - lat1) * p) / 2 +
        (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;
      return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
    },
    backUser() {
      this.first--;
      if (this.index == 1 && this.totalAll > this.total) {
        this.backMoreUser();
        return;
      }
      this.$root.$refs.loading.start();
      this.isSendMessage = false;
      this.isLike = false;
      this.inputMsg = null;
      if (this.index == 1 && this.totalAll == 1) {
        this.index = 1;
      } else if (this.totalAll !== 1 && this.index != 1) {
        this.index--;
      }
      this.$root.$refs.loading.finish();
    },
    nextUser() {
      this.first++;
      if (this.total === this.index && this.total <= this.totalAll) {
        this.loadMoreUser();
        return;
      }
      this.$root.$refs.loading.start();
      this.isSendMessage = false;
      this.isLike = false;
      this.inputMsg = null;
      if (this.index == 2 && this.totalAll == 2) {
        this.index = 1;
      } else if (this.totalAll !== 1) {
        this.index++;
      }
      this.$root.$refs.loading.finish();
    },
    async loadMoreUser() {
      this.$root.$refs.loading.start();
      this.suggest = true;
      const listUserSuggest = [];
      this.type =
        this.distanceToUser() > 15 ? TYPE_USER_SUGGEST_2 : TYPE_USER_SUGGEST_1;
      let listUsers = localStorage.getItem("listUsersSuggest")
        ? JSON.parse(localStorage.getItem("listUsersSuggest"))
        : [];
      let length = listUsers.length;
      if (listUsers.length > this.totalAll && this.totalAll > 3) {
        const userOne =
          this.distance(
            this.userLogin.latitude,
            this.userLogin.longitude,
            this.listUsers[0].latitude,
            this.listUsers[0].longitude
          ) > 15
            ? 2
            : 1;
        const userTwo =
          this.distance(
            this.userLogin.latitude,
            this.userLogin.longitude,
            this.listUsers[1].latitude,
            this.listUsers[1].longitude
          ) > 15
            ? 2
            : 1;
        const userThree =
          this.distance(
            this.userLogin.latitude,
            this.userLogin.longitude,
            this.listUsers[2].latitude,
            this.listUsers[2].longitude
          ) > 15
            ? 2
            : 1;
        if (userOne == userTwo && userOne != userThree) {
          listUserSuggest.push(listUsers[length - 1]);
        } else if (userOne == userThree && userOne != userTwo) {
          listUserSuggest.push(listUsers[length - 2]);
          listUserSuggest.push(listUsers[length - 1]);
        } else {
          for (let i = 0; i < LIMIT_USER_SUGGEST; i++) {
            if (length) {
              listUserSuggest.push(listUsers[length - 1]);
            }
            length--;
          }
        }
        await localStorage.setItem(
          "listUsersSuggest",
          JSON.stringify(listUserSuggest)
        );
      } else if (listUsers.length == this.totalAll) {
        await localStorage.removeItem("listUsersSuggest");
        this.type =
          this.distanceToUser() > 15
            ? TYPE_USER_SUGGEST_1
            : TYPE_USER_SUGGEST_2;
      }

      this.listUsers = null;
      await this.getListUsers(this.type);
      this.isLike = false;
      this.isSendMessage = false;
      this.inputMsg = null;
      this.index = 1;
      this.$root.$refs.loading.finish();
    },
    async backMoreUser() {
      this.$root.$refs.loading.start();
      let listUsers = localStorage.getItem("listUsersSuggest")
        ? JSON.parse(localStorage.getItem("listUsersSuggest"))
        : [];
      for (let index = 1; index <= this.total; index++) {
        this.listUsersSuggest.splice(this.listUsersSuggest.length - 1, 1);
        listUsers.splice(listUsers.length - 1, 1);
      }
      localStorage.setItem("listUsersSuggest", JSON.stringify(listUsers));
      this.listUsers = [];
      const length = this.listUsersSuggest.length;
      this.listUsers = [
        this.listUsersSuggest[length - 3],
        this.listUsersSuggest[length - 2],
        this.listUsersSuggest[length - 1]
      ];
      this.listUsers.push();
      this.suggest = true;
      this.isLike = false;
      this.isSendMessage = false;
      this.inputMsg = null;
      this.index = 3;
      this.$root.$refs.loading.finish();
    },
    pressEnter(event) {
      if (
        event.keyCode === 13 &&
        !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        event.preventDefault();
      }
    },
    handleSubmitKeyboard(e) {
      if (
        !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        // this.handleSubmit(e);
        let val = e.target.value;
        let selStart = e.target.selectionStart;
        e.target.value =
          val.slice(0, selStart) + "\n" + val.slice(e.target.selectionEnd);
        e.target.selectionEnd = e.target.selectionStart = selStart + 1;
        if (e.target.selectionEnd === e.target.textLength) {
          let textarea = document.getElementById("textarea");
          textarea.scrollTop = textarea.scrollHeight;
        }
      } else {
        let val = e.target.value;
        let selStart = e.target.selectionStart - 1;
        e.target.value =
          val.slice(0, selStart) + "\n" + val.slice(e.target.selectionEnd);
        e.target.selectionEnd = e.target.selectionStart = selStart + 1;
      }
    },
    async focusMessage() {
      if (
        (this.authUser.sex === 1 && !this.monthlyPlanStatus) ||
        (this.authUser.sex === 2 && this.authUser.identity_status != 3)
      ) {
        this.readonlyMsg = 1;
        let data = {
          hiddenUserIds: {
            [this.userLogin.user_id]: true,
            [this.listUsers[this.index - 1].user_id]: true
          },
          userIds: [
            this.userLogin.user_id,
            this.listUsers[this.index - 1].user_id
          ],
          adId: null,
          type: INDIVIDUAL_ROOM_TYPE
        };
        await this.$store
          .dispatch("firebase/createRoom", data)
          .then(result => {
            this.$router.push({
              name: "MessageDetail",
              params: { id: result }
            });
          })
          .catch(error => {
            this.roomId = null;
            throw error;
          });
        return;
      }
    },
    identify() {
      if (this.$route.name !== "CastMyPageIdentifyProfile")
        this.$router.push({ name: "CastMyPageIdentifyProfile" });
      this.$refs.identifyPopup.closeModal();
      return;
    },
    // blur() {
    //   if (window.innerWidth < 800) {
    //     document.getElementById("form-message").style.cssText =
    //       "position: unset; left: unset; transform: unset";
    //   }
    // },
    // focus() {
    //   if (window.innerWidth < 800) {
    //     document.getElementById("form-message").style.cssText =
    //       "position: absolute; bottom: 30px; z-index: 10; width: 100%; left: 50%; transform: translate(-50%, -50%);";
    //   }
    // },
    focusMessageIn(event) {
      let style;
      let el = event.target;
      if (event.keyCode !== 13) {
        if (window.innerWidth < 1080) {
          style = "height: 37px; padding: 8px 10px;";
        } else {
          style = "height: 52px; padding: 16px;";
        }
        let cssText = "height: auto;" + style;
        el.style.cssText = cssText;
        el.style.cssText = "height:" + el.scrollHeight + "px";
      } else {
        event.preventDefault();
      }
    },
    dblclick() {},
    nextProfile() {
      if (this.listUsers[this.index - 1].sex === 2) {
        this.$root.$refs.loading.start();
        this.$router.push({
          name: "CastProfile",
          params: {
            id: this.listUsers[this.index - 1].user_id
          },
          query: {
            showPopup: true,
            type:
              this.distanceToUser() > 15
                ? TYPE_USER_SUGGEST_2
                : TYPE_USER_SUGGEST_1
          }
        });
        this.$root.$refs.loading.finish();
      } else {
        this.$root.$refs.loading.start();
        this.$router.push({
          name: "MenProfile",
          params: {
            id: this.listUsers[this.index - 1].user_id
          },
          query: {
            showPopup: true,
            type:
              this.distanceToUser() > 15
                ? TYPE_USER_SUGGEST_2
                : TYPE_USER_SUGGEST_1
          }
        });
        this.$root.$refs.loading.finish();
      }
    },
    sendMessageToUserLine(roomId) {
      let url = window.location.origin + "/messages/" + roomId;
      this.$store.dispatch("auth/sendMessageToNotifiedByLine", {
        user_id: this.listUsers[this.index - 1].user_id,
        message: "",
        channel_url: url,
        title: "新しいメッセージを受信しました"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
#view-distance {
  padding-bottom: 70px;
  background: url("~@/assets/image/background-popup.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media screen and (max-width: 800px) {
    min-height: calc(100vh + 50px);
    height: fit-content;
  }

  .image-avatar {
    width: fit-content;
    margin: auto;
    cursor: pointer;
  }
  .avatar {
    width: 337px;
    height: 337px;
    border-radius: 12px;
    box-shadow: #26262680 2px 3px 4px;
    margin: auto;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
    margin-top: 16px;
    img {
      width: 100%;
      height: 100%;
    }
    @media screen and (min-width: 1079px) {
      width: 540px;
      height: 540px;
    }
  }

  .nick__name {
    margin: auto;
    margin-bottom: 13px;
    @media screen and (min-width: 1079px) {
      margin-bottom: 32px;
    }
  }
  .nick-name {
    font-size: 16px;
    line-height: 24px;
    @media screen and (min-width: 1079px) {
      font-size: 25px;
      line-height: 37px;
    }
  }
  .view-distance__distance {
    align-items: center;
    font-size: 14px;
    .distance {
      margin-left: 7px;
      margin-right: 7px;
    }
    @media screen and (min-width: 1079px) {
      // margin-top: 12px;
      font-size: 22px;
      img {
        width: 18px;
        height: 23px;
      }
      .distance {
        margin-left: 12px;
        margin-right: 12px;
      }
    }
  }
  .view-distance--detail {
    // margin-top: 17px;
    &__text {
      font-size: 14px;
      &.__right {
        margin-left: 16px;
      }
    }
    .__detail {
      .view-distance-point {
        align-items: center;
        &__text {
          font-size: 14px;
          margin-bottom: 0;
        }
        &__point {
          font-size: 18px;
          color: #464d77;
          margin-bottom: 0;
          margin-left: 2px;
        }
      }
      .border-score {
        width: 1px;
        height: 26px;
        background-color: #dedede;
        margin-left: 12px;
        margin-right: 12px;
      }
    }
    @media screen and (min-width: 1079px) {
      // margin-top: 25px;
      &__text {
        font-size: 16px;
      }
      .__detail {
        .view-distance-point {
          &__text {
            font-size: 16px;
          }
          &__point {
            font-size: 22px;
          }
        }
      }
    }
    .router {
      margin: auto;
      width: fit-content;
      margin-top: 14px;
      font-size: 14px;
      cursor: pointer;
      height: 21px;
      @media screen and (min-width: 1079px) {
        font-size: 16px;
        margin-top: 32px;
        height: 24px;
      }
    }
    .like {
      width: 78px;
      height: 78px;
      border-radius: 50%;
      background: linear-gradient(#626262, #4b59ab);
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 20px 40px 16px 40px;
      font-size: 14px;
      cursor: pointer;
      user-select: none;
      .image-like {
        margin-bottom: 6px;
        pointer-events: none;
        svg {
          width: 50px;
          @media screen and (max-width: 1079px) {
            width: 36px;
          }
        }
      }
      &.liked {
        background: linear-gradient(#2184f3, #18b87c);
        pointer-events: none;
      }
      @media screen and (min-width: 1079px) {
        width: 124px;
        height: 124px;
        margin-top: 18px;
        margin-bottom: 30px;
        font-size: 24px;
        img {
          width: 37px;
          height: 37px;
        }
      }
    }
    .skip {
      cursor: pointer;
      width: fit-content;
      img {
        width: 24px;
        height: 18px;
        &.__image {
          width: 16px;
          height: 16px;
        }
      }
      font-size: 16px;
      color: #626262;
      @media screen and (min-width: 1079px) {
        font-size: 24px;
        img {
          width: 36px;
          height: 26px;
          &.__image {
            width: 34px;
            height: 34px;
          }
        }
      }
    }
  }
  textarea {
    flex-grow: 1;
    padding: 8px 10px;
    resize: none;
    max-height: 42px;
    // background-color: #f5f5f5;
    font-size: 14px;
    line-height: 19px;
    height: 37px;
    border-radius: 0px;
    @media screen and (min-width: 1079px) {
      max-height: 70px;
      padding: 16px;
      font-size: 16px;
      height: 52px;
    }
  }
  .btn-send {
    box-shadow: none;
    outline: none;
    border-radius: 0px;
    img {
      width: 26.36px;
      height: 26.36px;
    }
    background-color: #ffffff;
    padding: 4px 10px;
  }
  .__disabled {
    pointer-events: none;
    img {
      opacity: 0.5;
    }
  }
  .form-message {
    display: flex;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid #e5e5e5;
    overflow: hidden;
    margin: auto;
    margin-top: 50px;
    max-width: calc(100% - 38px);
    @media screen and (min-width: 1079px) {
      margin-top: 30px;
      max-width: 540px;
    }
    @media screen and (min-height: 700px) and (max-width: 900px) {
      position: fixed;
      bottom: 30px;
      width: 100%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  textarea::placeholder {
    color: #9c9c9c;
    font-size: 14px;
    margin-top: 100px;
  }
  .image-send-message {
    pointer-events: none;
  }
}
</style>
<style>
.b-toaster {
  z-index: 100000;
}
</style>
