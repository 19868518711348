<template>
  <div id="dot" class="d-flex justify-content-end f-w3">
    <div class="dot-item" v-for="i in total" :key="i">
      <div class="dot-item-view" :class="i === index ? 'active' : ''"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      default: 1
    },

    total: {
      type: Number,
      default: 1
    }
  }
};
</script>

<style lang="scss" scoped>
#dot {
  margin-top: 10px;
  margin-bottom: 5px;
  @media screen and (min-width: 1079px) {
    margin-top: 16px;
    margin-bottom: 6px;
  }
  .dot-item-view {
    width: 6px;
    height: 6px;
    margin: 2px;
    background-color: #d8d8d8;
    border-radius: 50%;
    &.active {
      background-color: #464d77;
    }
    @media screen and (min-width: 1079px) {
      width: 10px;
      height: 10px;
    }
  }
}
</style>
