<template>
  <div
    class="create-order f-w3"
    v-if="!checkPackagePaymentFail && showMessage === null"
  >
    <div class="note-trial f-w3">
      <div class="top" v-if="checkPackage">
        <span class="number">3</span>
        <span class="text">ヶ月プランでお得に楽しもう</span>
      </div>
      <div class="top" v-else>
        <span class="number">28</span>
        <span class="text">日間の無料トライアルを利用する</span>
      </div>
      <div class="bottom" v-if="!checkPackage">
        <div>
          <img
            :src="require('@/assets/image/tick.svg')"
            class="button__icon"
            alt="like"
          />
          <span>全ての人にメッセージが送れる</span>
        </div>
        <div>
          <img
            :src="require('@/assets/image/tick.svg')"
            class="button__icon"
            alt="like"
          />
          <span>返信率の上がるSuperオファーが利用可能</span>
        </div>
        <div>
          <img
            :src="require('@/assets/image/tick.svg')"
            class="button__icon"
            alt="like"
          />
          <span>いつでもキャンセルできます</span>
        </div>
      </div>
    </div>
    <div class="plan-select f-w3 d-flex flex-column">
      <div>
        <input
          type="radio"
          id="customRadio1"
          v-model="packet"
          :value="1"
          class="custom-control-input"
        />
        <label class="plan-label f-w6" for="customRadio1">
          <div class="plan" :class="{ active: packet === 1 }">
            <div class="note">
              <span v-if="!checkPackage">無料トライアル後</span>
            </div>
            <div class="plan-detail plan-detail-top">
              <div class="price f-w6">
                1,500 <span class="f-w6">円/月</span>
              </div>
              <div class="time">1か月プラン</div>
            </div>
          </div>
        </label>
      </div>
      <div>
        <input
          type="radio"
          id="customRadio2"
          v-model="packet"
          :value="2"
          class="custom-control-input"
        />
        <label class="plan-label f-w6" for="customRadio2">
          <div class="plan plan-bottom" :class="{ active: packet === 2 }">
            <div class="note">
              <span v-if="!checkPackage">無料トライアル後</span>
            </div>
            <div class="plan-detail">
              <div class="price f-w6">980 <span class="f-w6">円/月</span></div>
              <div class="time">3か月プラン <br /><span>（2,940円）</span></div>
            </div>
            <div class="package_sale">
              <img
                :src="require('@/assets/image/sale_package.svg')"
                class="button__icon"
                alt="like"
              />
            </div>
            <div class="discount f-w6">
              <span class="number">30</span>
              <span class="off"
                ><span class="off-top">%</span><span>OFF</span></span
              >
            </div>
          </div>
        </label>
      </div>
    </div>
    <div class="plan-choose-master" v-if="!checkPackage">
      <div class="plan-choose">
        <div class="left">
          <div>本日のお支払い（28日間無料）</div>
          <div>{{ getDatePlan(28) }}より</div>
        </div>
        <div class="right">
          <div>¥0</div>
          <div>¥{{ getPricing }}</div>
        </div>
      </div>
    </div>
    <div class="plan-choose-time" v-else>
      <hr class="line" />
      <div class="plan-time">
        <div class="time-start">
          <span>有効期限</span>
          <div class="time-package">{{ getTimeEnd(-1) }}</div>
        </div>
        <div class="time-end">
          <span>次回更新日</span>
          <div class="time-package">{{ getTimeEnd() }}</div>
        </div>
      </div>
    </div>
    <div class="plan-policy">
      <p>上記の価格は税込価格になります。</p>
      <p>キャンセルはいつでも可能です。</p>
      <p>サブスクリプションは自動更新されます。</p>
      <p>
        登録をすると、当社の
        <router-link to="/my-page/term-of-use" active-class="active"
          >利用規約・サブスクリプション契約</router-link
        >
        と
        <router-link to="/my-page/policy" active-class="active"
          >プライバシーポリシー</router-link
        >
      </p>
      <p>に同意されたものとみなします。</p>
      <p>Superオファーの利用には別途PreGo内のポイント</p>
      <p>が必要です。</p>
    </div>
    <div class="plan-button f-w3">
      <button @click="addMonthlyPlan" v-bind:disabled="checkDisabled()">
        <span v-if="!checkPackage"> 無料トライアルを開始</span>
        <span v-else> はじめる</span>
      </button>
    </div>
  </div>
  <div v-else class="create-order f-w3 modal-noti-payment-fail">
    <div v-if="checkPackagePaymentFail">
      <p class="modal-logout-title f-w3">
        <br />
        ご登録いただいたカードでは決済が完了できませんでした。<br />
        マイページのプロフィール編集よりクレジット情報をご確認のうえ再度プランにお申し込み頂くか、別のクレジットカードのご登録をお願い致します。<br />
      </p>
      <div class="d-flex justify-content-center f-w3">
        <button @click="checkCredit" class="btn-check-card">
          クレジットカード登録
        </button>
      </div>
    </div>
    <div v-else>
      <p class="modal-logout-title f-w3">
        <br />
        「ご登録済のクレジットカード」の有効期限が切れているため決済できません。<br /><br />
        下記の方法で再度ご登録をお願い致します。<br />
        【マイページ】→【設定】→【アカウント】→【クレジットカードの情報・登録】<br />
      </p>
      <div class="d-flex justify-content-center f-w3">
        <button @click="checkCredit" class="btn-check-card">
          クレジットカード登録
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { SEND_CONTINUE_POPUP } from "@/utils/const";

export default {
  name: "AddMonthlyPlanPopup",

  data() {
    return {
      packet: 0,
      price: "0",
      isButtonDisabled: true,
      showMessage: null
    };
  },
  props: {
    roomId: {
      type: String,
      default: null
    },
    routePage: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      cardInfo: "payment-stripe/cardInfo",
      authUser: "auth/user",
      monthlyPlanStatus: "payment-stripe/monthlyPlanStatus"
    }),
    getPricing() {
      if (this.packet == 1) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.price = "1,500";
      } else if (this.packet == 2) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.price = "2,940";
      }
      return this.price;
    },
    checkPackage() {
      return this.authUser.check_trial_package;
    },
    checkPackagePaymentFail() {
      return this.authUser.check_package_payment_fail;
    }
  },
  methods: {
    ...mapActions("payment-stripe", [
      "addPaymentMonthPacket",
      "getPaymentMonthPacket"
    ]),
    ...mapActions("auth", ["getUsersPackage"]),
    checkDisabled() {
      return this.packet === 0 ? true : false;
    },
    getDatePlan(dayPlus = 0) {
      const date = new Date();
      date.setDate(date.getDate() + dayPlus);
      return this.fomatDate(date);
    },
    getTimeEnd(dayPlus = 0) {
      const date = new Date();
      let date_result = "";
      if (this.packet === 1) {
        date_result = this.fomatDate(this.addMonths(1, date, dayPlus));
      } else if (this.packet === 2) {
        date_result = this.fomatDate(this.addMonths(3, date, dayPlus));
      }
      return date_result;
    },
    addMonthlyPlan() {
      this.$root.$refs.loading.start();
      if (this.roomId) {
        this.$store.dispatch("firebase/updateRoomCheckDisplayPopup", {
          roomId: this.roomId,
          value: SEND_CONTINUE_POPUP.DISPLAY_POPUP
        });
      }
      if (this.cardInfo) {
        this.addPaymentMonthPacket({
          packet_type: this.packet
        })
          .then(() => {
            this.$emit("close-modal");
            this.$emit("add-success");
            let message_success =
              "登録が完了しました。お相手にメッセージが送れます";
            if (this.routePage == "NotMessage") {
              message_success = "登録が完了しました。";
            }
            this.$parent.$parent.$data.showContinueDatePopup = true;
            this.$parent.$parent.$data.readonlyMsg = 0;
            localStorage.removeItem("package_user_" + this.authUser.user_id);
            setTimeout(() => {
              this.$toast(message_success, "通知", "success");
              this.getPaymentMonthPacket();
              this.getUsersPackage();
            }, 1000);
            this.$root.$refs.loading.finish();
            if (this.$route.name == "Home" || this.$route.name == "MenMyPage") {
              this.$router.push({ name: "MenOrderList" });
            }
          })
          .catch(error => {
            if (error.response.data.error.code == "5044") {
              this.showMessage = error.response.data.error.message;
              this.$root.$refs.loading.finish();
            } else {
              this.showMessage = null;
              this.$toast("登録に失敗しました。", "通知", "danger");
              this.$root.$refs.loading.finish();
            }
          });
      } else {
        const localStorageName = "package_user_" + this.authUser.user_id;
        localStorage.setItem(localStorageName, this.packet);
        this.$root.$refs.loading.finish();
        this.$router.push({ name: "MenRegisterCardCredit" });
      }
    },
    fomatDate(datetime) {
      const date = new Date(datetime);
      const lang = "ja",
        year = date.toLocaleString(lang, { year: "numeric" }),
        month = date.toLocaleString(lang, { month: "numeric" }),
        day = date.toLocaleString(lang, { day: "numeric" });
      return `${year}${month}${day}`;
    },
    addMonths(numOfMonths, date = new Date(), dayPlus) {
      date.setMonth(date.getMonth() + numOfMonths);
      date.setDate(date.getDate() + dayPlus);
      return date;
    },
    checkCredit() {
      this.$router.push({ name: "InfoCardCredit" });
    }
  }
};
</script>

<style scoped lang="scss">
.app-modal__dialog {
  max-width: 375px;
}
.create-order {
  max-width: 375px;
  .note-trial {
    margin: 30px 0;
    .top {
      font-weight: bold;
      line-height: 27px;
      margin-bottom: 13px;
      .number {
        font-size: 32px;
        line-height: 27px;
      }
      .text {
        font-size: 18px;
      }
    }
    .bottom {
      font-size: 11px;
      div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 5px;
        margin-left: 60px;
        margin-bottom: 6px;
      }
    }
  }

  .plan-select {
    .plan-label {
      display: block;
      margin-bottom: 20px;
    }
    .plan {
      max-width: 307px;
      border-radius: 20px;
      background: #f5f5f5;
      border: 3px solid #f5f5f5;
      cursor: pointer;
      margin: auto;
      margin-bottom: 20px;
      position: relative;
      min-height: 82px;
      .note {
        font-size: 9px;
        text-align: left;
        margin-left: 20px;
        margin-top: 18px;
        color: #212121;
      }
      .plan-detail {
        display: flex;
        flex-wrap: wrap;
        column-gap: 10px;
        margin-bottom: 13px;
        .price {
          font-size: 28px;
          width: 47%;
          text-align: left;
          padding-left: 20px;
          word-break: keep-all;
          span {
            font-size: 14px;
            word-break: keep-all;
            margin-left: -5px;
          }
        }
        .time {
          font-size: 14px;
          span {
            font-size: 11px;
          }
        }
      }
      .plan-detail-top {
        align-items: baseline;
      }
      .package_sale {
        position: absolute;
        top: -26px;
        right: 0;
      }
      .discount {
        display: flex;
        position: absolute;
        top: -10px;
        right: 35px;
        align-items: center;
        .number {
          font-size: 24px;
        }
        .off {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          font-size: 9px;
          .off-top {
            line-height: 5px;
            margin-top: 7px;
          }
        }
      }
    }
    .plan-bottom {
      background: linear-gradient(90deg, #18b680 0%, #16989d 100%);
      max-width: 334px;
      color: #ffffff;
      .note {
        color: #ffffff;
        font-weight: 500;
        font-family: "Hiragino Kaku Gothic Pro W3";
        margin-left: 37px;
      }
      .plan-detail {
        .price {
          padding-left: 37px;
        }
        .time span {
          font-weight: 500;
          font-family: "Hiragino Kaku Gothic Pro W3";
        }
      }
    }
    .active {
      border: 3px solid #464d77;
      .package_sale {
        top: -28px;
      }
    }
  }
  .plan-choose-master {
    display: flex;
    justify-content: center;
    .plan-choose {
      font-size: 11px;
      letter-spacing: 0;
      line-height: 17px;
      display: flex;
      text-align: left;
      flex-wrap: wrap;
      column-gap: 25px;
      margin: 0 0 40px 0;
      .left {
        max-width: 160px;
      }
      .right {
        font-weight: 600;
      }
    }
  }
  .plan-choose-time {
    width: 90%;
    margin: auto;
    margin-bottom: 30px;
    .line {
      width: 100%;
      margin-bottom: 10px;
    }
    .plan-time {
      display: flex;
      .time-start {
        border-right: 1px solid #e6e6e6;
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 10px;
      }
      .time-end {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 20px;
      }
      span {
        font-size: 11px;
        margin-bottom: 5px;
      }
      .time-package {
        font-weight: 600;
        font-size: 14px;
      }
    }
  }

  .plan-policy {
    max-width: 307px;
    width: 100%;
    padding: 16px 15px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23333' stroke-width='1' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
    border-radius: 20px;
    margin: auto;
    margin-bottom: 36px;
    p {
      font-size: 10px;
      letter-spacing: 0;
      line-height: 17px;
      margin: 0;
      text-align: left;
      a {
        text-decoration: underline;
      }
    }
  }

  .plan-button {
    button {
      max-width: 307px;
      width: 100%;
      background: #464d77;
      font-size: 16px;
      color: #ffffff;
      padding: 13px;
      letter-spacing: 0px;
      border-radius: 5px;
    }
    button[disabled] {
      background-color: #e5e5e5;
      color: #b8b8b8;
    }
  }
  .custom-control-label::after {
    display: none !important;
  }
  .btn-check-card {
    width: 210px;
    height: 45px;
    color: #ffffff;
    background-color: #464d77;
    font-size: 16px;
    border-radius: 5px;
  }
}
@media only screen and (min-width: 1079px) {
  .create-order {
    max-width: 375px;
    .plan-button {
      button {
        width: 307px;
        margin: 0 14px 5px 14px;
      }
    }
  }
}

@media screen and (min-width: 416px) and (max-width: 435px) {
  .create-order .plan-select .plan .plan-detail {
    column-gap: 2px;
    .price {
      font-size: 26px;
      width: 49%;
      span {
        font-size: 13px;
      }
    }
    .time {
      font-size: 13px;
    }
  }
}

@media screen and (min-width: 376px) and (max-width: 415px) {
  .create-order .plan-select .plan .plan-detail .price {
    font-size: 24px;
    span {
      font-size: 13px;
    }
  }

  .create-order .plan-select .plan .plan-detail {
    column-gap: 2px;
  }
}

@media only screen and (max-width: 376px) {
  .create-order {
    padding: 5px;
    .note-trial {
      margin: 20px 0;
      .top .number {
        font-size: 28px;
        line-height: 20px;
      }
    }
    .plan-select .plan {
      .plan-detail {
        column-gap: 0;
        .price {
          font-size: 24px;
          span {
            font-size: 13px;
          }
        }
      }
    }
    div.plan-bottom .plan-detail {
      div.price {
        padding-left: 30px !important;
      }
    }
    .plan-policy {
      margin-bottom: 20px;
    }
    .plan-choose-time {
      margin-bottom: 20px;
    }
  }
}
</style>
