<template>
  <div
    id="header-suggest"
    class="d-flex justify-content-center align-items-center"
  >
    <div
      class="button-back d-flex justify-content-center align-items-center"
      @click="backToHome()"
    >
      <span class="mb-0">ホーム画面へ</span>
    </div>
    <img class="logo" alt="logo-prego" src="../assets/image/logo-prego.png" />
  </div>
</template>
<script>
export default {
  methods: {
    backToHome() {
      this.$emit("closeModalSuggestUser");
    }
  }
};
</script>

<style lang="scss" scoped>
#header-suggest {
  background-color: #ffffff;
  height: 42px;
  border-bottom: 1px solid #dcdcdc;
  position: relative;
  width: 100%;
  .button-back {
    border-radius: 8px;
    border: 1px solid #e5e5e5;
    background: #f5f5f5 0% 0% no-repeat padding-box;
    font-size: 12px;
    width: 105px;
    height: 31px;
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    left: 19px;
    cursor: pointer;
  }
  .logo {
    width: 80px;
    height: 20px;
  }

  @media screen and (min-width: 1079px) {
    height: 61px;
    .button-back {
      width: 110px;
      font-size: 14px;
      span {
        margin-left: 7px;
      }
    }
    .logo {
      width: 135px;
      height: 34px;
    }
  }
}
</style>
